.login-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Login/FONDO-DEL-FORMULARIO-DE-INICIO-DE-SESIO%CC%81N.jpg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}

.login-left-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Login/FondoLoginAzul.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}



.login-content{
  background-image: url("https://drophacking.blob.core.windows.net/images/Login/cuadro-para-formulario.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.sub-title-white-login{
  color: white;
  font-size: 25pt;
  line-height: 25pt;
  font-weight: 200;
}

.blue-line{
  background-color: #0a1a55;
  height: 4px;
  width: 100px;
  border-radius: 10px;
  margin-bottom: 20pt;
}

/*.blue-bar{
  background-color: #0a1a55;
  height: 2vh;
}

.login-layout{
  height: 96vh;
  padding: 100px 0px
}

 .login-container{
  height: 70vh;
  -webkit-box-shadow: 0px 0px 10px 0px #000000; 
  box-shadow: 0px 0px 10px 0px #000000;
  border-radius: 10px;
} */

.left-block{
  text-align: center;
  background-color: #0a1a55;
  height: 70vh;
  background-image: url("https://drophacking.blob.core.windows.net/images/Login/FondoLoginAzul.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.container-left-block{
  margin-top: 150pt;
  text-align: center;
}

.right-block{
  height: 70vh;
}

  .white-container{
    background-color: white;
    padding-right: 20px;
  }

.gray-bar{
  background-color: lightgray;
  height: 70vh;
  width: 2vh;
}

.gray-text{
  margin-top: 50pt;
  color: gray;
  font-size: 20pt;
  font-weight: bold;
}

.sub-title-blue{
  color:#0a1a55;
  font-weight: 200;
  font-size: 38pt;
  line-height: 38pt;
}

.description-text{
  color: gray;
  font-size: 20pt;
  font-weight: 200;
  line-height: 20pt;
  margin-bottom: 20pt;
}

.input{
  width: 100%;
  margin-bottom: 10pt;
  border-radius: 10px;
}

.remenber-pass{
  color: gray;
  font-size: 12pt;
  font-weight: 200;
  margin-bottom: 20pt;
  text-align: right;
  margin-top: 20px;
}

.btn-reset-pass:hover{
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  
}

.btn-login{
  background-color: #0a1a55;
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 20px;
}
.warehouseInventory {
  display: block;
}

.warehouse-inventory__select {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.warehouse-inventory__header{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.warehouse-inventory__actions{
  display: flex;
  gap: 10px;
}

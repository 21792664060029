.modalForgotPassword {
    display: block;
    padding: 10px;
}

.linkPassword {
    text-decoration: underline;
    cursor: pointer;
}

.linkPassword:hover {
    opacity: .7;
}

.recoveryPassword {
    display: block;
}

.contenedorPassword{
    padding: 25px;
    text-align: center;
    margin-top: 80px;
    border-radius: 10px;
    height: 400px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(173,170,173,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(173,170,173,1);
    box-shadow: 0px 0px 10px 0px rgba(173,170,173,1);
  }

.contenedorLogo{
width: 100px;
    margin: 0 auto;
    padding: 15px;
    height: 100px;
    border-radius: 50px;
    box-shadow: 0px 0px 10px 0px #adaaad;
    margin-bottom: 20px;
}
  
  .btnSavePassword{
    background-color: #021435;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-left: 15px;
    cursor: pointer;
  }
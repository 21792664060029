.fondoChange{
    background-image: url("../../Assets/fondoSesion6.jpg");
    min-height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgChange{
    width: 80%!important;
    padding-top: 100px;
    text-align: center;
  }

  .buttonQuiero{
    width: 40%;
  }


  @media (max-width:600px){
    .buttonQuiero{
      width: 70%!important;
      padding-top: 50px;
    }
    .imgChange{
      width: 90%!important;
      padding-top: 130px!important;
    }
    
  }
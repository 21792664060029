.layout{
  display: grid;
  grid-template-rows: auto 1fr;
}

.container{
  display: grid;
  grid-template-columns: 5% 1fr;
}

.content{
  grid-column: 2;
}

.sidenav {
  height: 100%;
  position: fixed;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.chart {
  display: block;
}


.filter-Button{
  background: rgba(225, 225, 225, .2);
  border-radius: 10px;
  padding: 10px;
  color: white;
  width: 200px;
  margin: 0 auto;
}

.filter-Button:hover{
  cursor: pointer;
  font-weight: bold;
}

.chartIcon{
  font-size: 50px;
  color: white;
}

.chartIcon:hover{
  color: lightgray;
  cursor: pointer;
}

.datePicker{
  border-radius: 10px;
  width: 90%;
}
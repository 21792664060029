.storeWallet {
  display: block;
}

.contenedorSaldo{
  text-align: center;
  margin: 8px;
  padding: 0px!important;

}


.ValueContainer{
  -webkit-box-shadow: 1px 1px 8px 1px rgba(184,184,184,1);
  -moz-box-shadow: 1px 1px 8px 1px rgba(184,184,184,1);
  box-shadow: 1px 1px 8px 1px rgba(184,184,184,1);
  background-color: white;
  padding: 10px;
}

.tituloValor{
  color: gray;
}

.valueStyle{
  color:rgb(63, 134, 0);
  font-size: 15px;
  font-weight: bold;
}

.PageTitle{
  font-size: 40px;
  text-align: center;
  color:#021435;
  font-weight: bold;
}

.cardContainer{
  -webkit-box-shadow: 1px 1px 8px 1px rgba(184,184,184,1);
  -moz-box-shadow: 1px 1px 8px 1px rgba(184,184,184,1);
  box-shadow: 1px 1px 8px 1px rgba(184,184,184,1);
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  font-size: 20px;
}

.billing-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Facturar/FONDO-DE-FACTURAS.jpg");
  min-height: 88vh;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0); 
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0);
  padding-top: 30px;
}

.background-blanco{
  background-image: url("https://drophacking.blob.core.windows.net/images/Fondo-blanco.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.background-azul{
  background-image: url("https://drophacking.blob.core.windows.net/images/fondo-Azul.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.form-container-localization{
  background-image: url("https://drophacking.blob.core.windows.net/images/Facturar/FONDO-DE-FORMULARIO-DE-LOCALIZACIO%CC%81N.png");
  min-height: 88vh;
  background-repeat: no-repeat;
  text-align: center;
  max-width: 1200px;
}

.inputs-container{
  padding: 30px;
}

.inputs-container{
  padding: 10px;
}

.form-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Facturar/FONDO-DE-FORMULARIO-DE-CLIENTE.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  text-align: left;
  padding: 20px;
  width: 1100px;
  margin: 0 auto;
}

.product-list-container{
  color:white;
  min-height: 300px;
}

.form-description{
  font-size: 25px;
  font-weight: 200;
  color: white;
  line-height: 25px;
  text-align: left;
}

.title-form{
  color:#0a1a55;
  font-size: 35px;
  line-height: 35px;
  text-align: center;
  margin-top: 30px;
}

.bold-title-form{
  color:#0a1a55;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.form-icon{
  width: 20px;
  margin-right: 10px;
}

.ant-input-affix-wrapper{
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

.btn-container{
  text-align: center;
}

.btn-container img{
  width: 150px;
  cursor: pointer;
}
.contenedor{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    padding: 20px;
    padding-top: 0;

}
.btn-contratos{
    height: 30px;
    width: auto;
}

.panel-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Panel/FONDO-DEL-PANEL.png");
  min-height: 88vh;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0); 
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0);
  padding-top: 30px;
}

.left-panel{
  background: rgba(225, 225, 225, .2);
  border-radius: 50px;
}

.bg-1{
  background: rgba(225, 225, 225,.4);
  padding: 10px;
}
.bg-2{
  background: rgba(225, 225, 225,.4);
  padding: 10px;

}
.bg-3{
  background: rgba(225, 225, 225,.2);
  padding: 10px;

}

.logo-container img{
  width: 150px;
  margin: 0 auto;
  border-radius: 50%;
}

.mt-50{
  margin-top: 50px;
}

.user-name{
  font-weight: bold;
  font-size: 20px;
  margin-top: -10px;
  color: white!important;
}

.hola-text{
  font-size: 20px;
  margin-top: -10px;
  color: white!important;
  font-weight: 200;
}

.normal-text{
  font-size: 25px;
  color: white!important;
  font-weight: 200;
}
.bold-text{
  font-size: 25px;
  font-weight: bold;
  color: white!important;
}

.amount-value{
  font-size: 40px;
  font-weight: bold;
  color: white!important;
}

.line-h-20{
  line-height: 20px;
}

.line-h-40{
  line-height: 40px;
}

.panel-metric{
  padding: 10px;
  color:white;
  margin: 5px;
  border-radius: 10px;
  margin: 3px auto;
}

.panel-metric img,.panel-metrics img {
  width: 50px;
  margin: 0 auto;
}

.panel-title{
  font-size: 20px;
  font-weight: bold;
}

.panel-value{
  font-size: 20px;
  font-weight: 200;
}
.sideBar {
  min-height: 88vh;
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0); 
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0);
}

.pro-sidebar > .pro-sidebar-inner{
  background-color: lightgray!important;
}

.pro-sidebar > .pro-sidebar-inner:hover{
  color: rgb(72, 70, 70)!important;;
}


.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: #d3d3d300!important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
  color:gray!important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
  text-decoration: underline;
}
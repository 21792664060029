.fondo4{
    background-image: url("../../Assets/fondosesion4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
  }

.imgSeccion{
  width: 90%;
}

.textStatistics{
  text-align: center;
  font-size: 18px;
  padding-top:100px;
  margin:15px;
  height:300px
}

.textFirst{
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}



  @media (max-width:600px){
    .imgSeccion{
      width: 100%;
      padding-top: 0px!important;
    }
  }
.warehouse-inventory-admin__container{
  padding: 2.5rem;
}

.warehouse-inventory-admin__tab-container {
  display: flex;
  gap: 0.5rem;
}

.warehouse-inventory-admin__tab {
  border: 1px solid #f0f0f0;
  background: #fafafa;
  padding: 8px 16px
}
.printLabels {
  display: block;
  font-size: 30px;
}

.dataFontSize{
  font-size: 18px;
}

.borderTop{
  border-top: 1px solid black;
}

.borderRight{
  border-right: 1px solid black;
}

.padding{
  padding: 10px;
  font-size: 15px;
}
#element-to-print{
  margin-top: 50px;
  font-size: 30px;
}

.imgWarning{
  width: 80%;
}

/* Dashed border */
.dashed {
  border-top: 3px dashed #bbb;
}

@page {
  margin: 0.5cm;
  size: A3;
}

@media print {
  body * {
    font-size: 15px;
  }
  .imgWarning{
    width: 80%;
  }
  .pagebreak {page-break-after: always}
}

.leftPane{
    width: 20%;
    height: 100%;
    background-color: #f5f5f5;
    float: left;
    border-right: 1px solid #e0e0e0;
    padding: 10px;
    box-sizing: border-box;
}

.rightPane{
    width: 80%;
    height: 100%;
    float: left;
    padding: 10px;
    box-sizing: border-box;
}

.leftPaneTitle{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.leftPaneCategoryList{
    list-style: none;
    padding: 0;
    margin: 0;
}
.leftPaneCategoryItem{
    padding: 5px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
}

.leftPaneCategoryItem:hover{
    background-color: #e0e0e0;
    color: black;
}

.editIcon{
    float: right;
    margin-left: 10px;
    cursor: pointer;
}

.rightPaneTitle{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.editCategoryInput{
    width: 400px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.priceInput{
    width: 120px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.CardPriceContainer{
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 5px;
    background-color: #f5f5f5;
}

.checkboxItemList{
    list-style: none;
    padding: 0;
    margin: 0;
}

.checkboxItem{
    margin-bottom: 5px;
}

.CategoryButton{
    float: right;
    margin-left: 10px;
    width: 200px;
    margin-top: 20px;
    border-radius: 10px;
}

.selectedCategory{
    background-color: #161153;
    color: white;
    font-size: bold;
}
.contenedor {
    margin: 0 -16px;
}

.copy-title {
    background-color: #021435;
    padding: 10px;
}

.copy-title img {
    display: flex;
    width: 50%;  
    margin: 0 auto;
}

.card-container {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.btn-creating {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.btn-editing {
    display: flex;
    width: 40%;
    justify-content: center;
    background-color: #021435;
    visibility: hidden;
}

.contein-btn {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.card-folder:hover button {
    visibility: visible;
}  

.card-folder .product-name {
    font-size: 10px; 
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.card-image-null img {
    width: 85%;
}

.card-image-null {
    display: flex;
    justify-content: center;
}

.search {
    margin-left: 20px;
}
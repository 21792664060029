
.country-name {
  background-image: url("https://drophacking.blob.core.windows.net/images/CortinaTiendas/FONDO-CORTINA-DE-TIENDAS.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.mtop-100 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.text-center{
  text-align: center;
}

.weight-500{
  font-weight: 500;
}

.flag-container{
  width: 90%;
  transition: transform .2s;
  cursor: pointer;
}


.flag-container:hover{
  transform: scale(1.2);
}

.dropshiping-text{
  font-size:30px;
  line-height: 25px;
  font-weight: 100;
  color: white;
}

.country-text{
  font-size: 50px;
  line-height: 45px;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}
.pendingRecharge {
  display: block;
}

.PageTitle{
  font-size: 40px;
  text-align: center;
  color:#021435;
  font-weight: bold;
  margin-bottom: 50px;
}
.fondo2{
    background-image: url("../../Assets/fondo3.jpg");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .textDifferentiators{
      text-align: left;
      padding-top: 350px;
      color: white;
      line-height: 50px;
  }

  .textMaster{
    color: white;
    background-color: #021435;
    font-size: 18px;
    padding: 15px;
}

.textSmall{
    color: white;
    text-align: center;
    margin-left: 16px;
    margin-right: 15px;
    padding:15px;
    font-size: 18px;
}


.imgDroophooks{
    width: 101%;
    -webkit-box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85); 
    box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85);
}

.imgMaster{
    width: 80%;
}

  
@media (max-width:600px){
    .fondo2{
        padding-left: 30px;
    }
    .imgMaster{
        width: 100%;
    }
    .imgDroophooks{
        width: 100%;
    }
    .textDifferentiators{
        padding-top: 0px!important;
    }
  }
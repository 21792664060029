.header-container {
  background-color: #0a1a55;
  -webkit-box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85); 
  box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85);
}

.logo {
  width: 90%;
  margin: 20px;
}

.user-name-text {
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  color: white;
  padding-top: 10px;
}

.user-email-text {
  font-size: 15px;
  line-height: 25px;
  font-weight: 150;
  color: white;
  padding-top: 10px;
}

.user-icon {
  width: 25px;
  height: 25px;
}

.user-data-container {
  text-align: right;
  padding: 20px;
  background-color: #253165;
  cursor: pointer;
}

.pt-30 {
  padding-top: 30px;
}

.btn-cerrar-sesion {
  cursor: pointer;
  width: 150px;
}

.user-blue {
  color: #0a1a55;
  font-size: 25px;
  font-weight: bold;
}

.user-email-text-popover {
  color: #0a1a55;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
}

.text-popover {
  color: #0a1a55;
  font-size: 15px;
  font-weight: 300;
}

.text-popover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  border-radius: 0px 0px 0px 0px!important;
}
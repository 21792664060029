.fondoContact{
    background-image: url("../../Assets/fondo7.png");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

.titleForm{
  text-align: center;
  color: gray;
  padding-top: 150px;
  font-size: xx-large;
}

.textForm{
  text-align: left!important;
  font-size: large!important;
  padding: 5px!important;
  background: lightgray;
  color:gray;
  margin:5px;
  padding-left: 15px;
}

.buttonSend{
  background: #021435;
  color: white;
}

  @media (max-width:600px){
    .fondoReality{
      height: 600px;
    }

    .fondoContact{
      background-image:none;
    }
   
  }
.registrationComponent {
  display: block;
  padding: 20px;
}


.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
  background: #ffad00!important;
}
.ant-steps-item-process .ant-steps-item-icon{
  background: #ffad00!important;
}


.container-left-registro{
  background-image: url("https://drophacking.blob.core.windows.net/images/Registro/registroTienda.png");
  background-repeat: no-repeat;
  background-size: cover;
}

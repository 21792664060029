.bankRegistration {
  display: block;
  padding-top: 50px;
  text-align: left;
}

.contenedorClaveDinamica
{
  border-radius: 10px;
  border: 1px solid lightgrey;
  margin-top: 50px;
}

.textoCelular{
  font-weight: bold;
  color: gray;
}

.btnRegistrarBanco{
  float:right;
  margin-top: 100px;
}
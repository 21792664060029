.cardContainner{
    background: white;
    margin-left: 10px;
    margin-top: 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
   height: 500px;
}

.Titulo{
    font-weight: bold;
    color:#021435;
    font-size: 25px;
    margin-top: 50px;
}

.input{
    /* padding: 20px; */
}
.modalDetalleGuia {
  display: block;
}

.textGuia{
  text-decoration: underline;
  cursor: pointer;
  color:blue;
}

.borderTop{
  border-top: 1px solid black;
}

.borderRight{
  border-right: 1px solid black;
}

.padding{
  padding: 10px;
}
#element-to-print{
  margin-top: 50px;
  font-size: 30px;
}

.imgWarning{
  width: 80%;
}

/* Dashed border */
.dashed {
  border-top: 3px dashed #bbb;
}
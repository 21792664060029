.productDetail {
  display: block;
}

.ant-modal-footer{
  display: none;
}

.product-image-container{
  padding-top: 30px;
  text-align: center;
}

.product-image-container img{
  max-width: 400px;
  max-height: 500px;
}

.ant-modal-body{
  padding: 0;
}

.product-detail-name{
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;

  
}

.product-description-container{
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.product-detail-description{
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 14px;
  margin-bottom: 20px;
}

.product-detail-min-price {
  font-size: 20px;
  font-weight: 300;
  background-color: lightgray;
  padding: 3px;
}

.product-detail-price{
  color: green;
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 10px;
}

.product-detail-orange-price{
  background-color: orange;
  font-weight: bold;
  color: white;
  margin-left: 10px;
  padding: 3px;
  border-radius: 10px;
}

.orange-line{
  background-color: orange;
  height: 4px;
  width: 100px;
  border-radius: 10px;
}

.add-product-container{
  background: lightgray;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
}

.add-product-container img{
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
}

.add-product-text{
  font-weight: bold;
}
.billing {
  display: block;
}

.contenedorTotales{
  height: 80px;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.75);
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: white;
}

.tituloTotales{
  font-weight: bold;
}

.valorPositivo{
  font-size: 20px;
  color: green;
  text-align: center;
}

.valorNegativo{
  font-size: 20px;
  color: red;
  text-align: center;
}

.textoSaldo{
  font-size: 20px;
  margin-top: 8px;
  font-weight: bold;
  color: #021435;
  text-align: center;
}

.fondoReality{
    background-image: url("../../Assets/fondoSesion5.jpg");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .imgRealidad{
    width: 100%;
    padding-top: 550px;
  }

  .imgEliminar{
    width: 100%;
    padding-top: 160px;
  }

  .img2{
    width: 90%;
  }

  @media (max-width:600px){
    .imgRealidad{
      width: 100%;
      padding-top: 90px!important;
    }
    .imgEliminar{
      width: 100%;
      padding-top: 10px!important;
    }
  
  }
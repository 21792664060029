.generateBill {
  display: block;
}

.fondo-blanco{
  background-image: url("https://drophacking.blob.core.windows.net/images/Facturar/fondo-blanco-de-pantalla.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.cardContainner{
  background: white;
  margin-left: 10px;
  margin-top: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.imageContainer{
  text-align: center;
  margin-top: 20px;
}

.Titulo{
  font-weight: bold;
  color:#021435;
  font-size: 25px;
  margin-top: 50px;
}

.valorPositivo{
  font-size: 20px;
  color: green;
  text-align: right;
}

.valorNegativo{
  font-size: 20px;
  color: red;
  text-align: right;
}

.input{
  width: 90%;
  margin-bottom: 10px;
}

.contenedoDireccion{
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 15px;
  margin-top: 50px;
}

.radio-vertical {
  display: flex;
  height: 30px;
  line-height: 35px;
}
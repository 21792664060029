.settings__container {
  padding: 2.5rem;
}
.datosTienda{
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  background-color: rgb(239, 237, 237);
  border: solid 1px rgb(212, 212, 212) ;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Tahoma";
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page {
    width: 210mm;
    min-height: 300mm;
    padding: 5mm;
    margin: 10mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
}
.subpage {
    padding: 3mm;
    border: 1px lightgray;
    height: 300mm;
    text-align: justify;
    background: white;
}

.center{
    text-align: center;
}



@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

.red-border{
    border: 1px solid red;
}

.no-border{
    border: 0px;
}

.show-Content{
    display: block;
}

.hide-content{
    display: none;
}

.signatureContainer{
    text-align: left;
    width: '50%';
    display: inline-block;
}

.fondo1{
    background-image: url("../../Assets/fondo1.jpg");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .textDropHacking{
      text-align: center;
      font-size: 50px;
      color: rgb(105, 103, 103);
      
  }

  .line{
    background: #021435;
    height: 120px;
    margin-top: 180px;
  }

  .img{
    width: 80%;
  }

  .img{
       box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85);
 -webkit-box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85); 
  }



  @media (max-width:600px){
    .textDropHacking{
        font-size: 30px;
    }
    .imgResponsive{
      padding-top: 50px!important;
    }
  }
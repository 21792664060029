.buttons{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
}
.ant-modal-body{
    padding: 10px !important;
}

.document-img{
    width: 100px;
    border-radius: 6px;
}

.container-document{
    display: flex;
    align-items: center;
    gap: 10px;
    a{
        width: 100px;
    }
}
.ant-upload{
    display: flex;
    gap: 10px;
    align-items: center;
    max-width: 500px;
    justify-content: space-between;
    b{
        width: 250px;
    }

}
.ant-modal-content{
    padding-inline-start: 20px;
    width: fit-content;
}


.ant-row {
    &.update{
        display: flex;
        flex-flow: row wrap;
        min-width: 0;
        justify-content: center !important;
    }
    
}

.ant-modal-content{
    &.update-modal{
        border-radius: 6px;
    }

}


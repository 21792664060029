.settlement {
  display: block;
}

.total-container {
 border-radius: 10px;
 border: 1px solid gray;
 padding: 10px;
}

.total-value{
  font-size: 15px;
  font-weight: bold;
  margin-left: 3px;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  justify-content: flex-start !important;
}
.fondo{
    background-image: url("../../Assets/fondoBanner.jpg");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgCrecel{
      text-align: center;
      width: 80%;
  }

.header{
    background-color: #021435;
    height: 80px;
    -webkit-box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85); 
    box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.85);
}

.textHeader{
    padding-top: 25px;
    color: white;
    font-size: medium;
    cursor: pointer;
}

.textHeader:hover{
    border-bottom: 1px solid white;
    margin-bottom: 25px;
    cursor: pointer;
}


.buttonLogIn{
    color: white;
    font-size: large;
    border: 1px solid gray;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.523);
    text-align: center;
    height: 50px;
    padding-top: 10px;
}

.textWhite{
    color:white;
    text-align: center;
}

.flagColombia{
    width: '5%';
    padding-bottom:'15px'
}

.textColombia{
   width: 60%;
}

.botonInicio{
    cursor: pointer;
}

@media (max-width:600px){
    .header{
        padding-left: 30px;
    }

  .botonInicio{
      padding-top: 250px;
      cursor: pointer;
  }

    .flagColombia{
        width: '8%'!important;
        padding-bottom:'30px'!important;
    }
    .textColombia{
        width: 70%;
    }
    
  .imgCrecel{
   display: none;
}
.textHeader{
    padding-top: 80px;
    color: white;
    font-size: medium;
}

  }
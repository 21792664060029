.droppHooks-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Drophooks/FONDO-DE-DROPHOOKS.png");
  min-height: 88vh;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0); 
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0);
  padding-top: 50px;
}

.droppHooks-description{
  font-size: 25px;
  font-weight: 300;
  color: white;
}

.droppHooks-folder-container{
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #9eadc4;
}

.droppHooks-folder-title{
  background-color:#d7dee8;
  padding: 5px 25px;
  -webkit-box-shadow: 1px 7px 11px -4px #000000; 
  box-shadow: 1px 7px 11px -4px #000000;
  font-size: 25px;
  text-align: left;
}

.item-container{
  padding: 20px;
}

.item-container img{
  width: 100%;
}

.gray-line{
  background-color: gray;
  height: 4px;
  width: 100px;
  border-radius: 10px;
  margin-top: -5px;
}
.sales-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Facturar/FONDO-DE-FACTURAS.jpg");
  min-height: 88vh;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0); 
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0);
  padding-top: 50px;
}

.line-h-20-sales{
  line-height: 20px;
  text-align: left;
}

.bg-1-sales{
  background: rgba(225, 225, 225,.6);
  padding: 10px;
}

.line-h-40-sales{
  line-height: 40px;
  text-align: left;
}

.logo-container-sales img{
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
  border-radius: 50%;
}

.user-name-sales{
  font-weight: bold;
  font-size: 20px;
  margin-top: -10px;
  color: white!important;
}

.hola-text-sales{
  font-size: 20px;
  margin-top: -10px;
  color: white!important;
  font-weight: 200;

}

.normal-text-sales{
  font-size: 25px;
  color: white!important;
  font-weight: 200;
}
.bold-text-sales{
  font-size: 25px;
  font-weight: bold;
  color: white!important;
}

.panel-metrics{
  padding: 10px;
  color:white;
}

.amount-value-sales{
  font-size: 50px;
  font-weight: bold;
  color: white!important;
}

.menu-boton{
  color: #0a1a55;
  text-align: center;
  text-align: bold;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  padding: 3px;
}

.menu-boton:active .menu-boton-active{
  text-decoration: underline;
}

.menu-boton img{
  width: 15px;
  margin-right: 5px;
}
@import '~antd/dist/antd.css';

.bold-title-white{
  color:white;
  font-weight: bold;
  font-size: 50pt;
  line-height: 45pt;
}

.sub-title-white{
  color:white;
  font-size: 50pt;
  line-height: 45pt;
  font-weight: 200;
}

.bold-title-blue{
  color:#0a1a55;
  font-weight: bold;
  font-size: 40pt;
  line-height: 40pt;
}

.title-blue{
  color:#0a1a55;
  font-weight: 100;
  font-size: 40pt;
  line-height: 40pt;
}

.color-texto-gris{
  color: #7d8081;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  border-radius: 10px 0 0 10px;
  background-color: #0a1a55;
  color: white;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
  border-radius: 0px 10px 10px 0px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{
  border-radius: 10px
}

.ant-modal-mask{
background-color: #0a1a55ba;
}

.ant-input:placeholder-shown{
  border-radius: 10px;
}

.generic-btn{
  background-color: #0a1a55;
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
}

.generic-btn:hover{
  background-color: lightgray;
  color: #0a1a55;
  font-weight: bold;
}

.generic-container{
  background-image: url("https://drophacking.blob.core.windows.net/images/Facturar/FONDO-DE-FACTURAS.jpg");
  min-height: 88vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0); 
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000, 5px 14px 5px 5px rgba(0,0,0,0);
  padding-top: 50px;
}

.generic-container > .ant-row{
  margin-left: 0px!important;
    margin-right: 0px!important;
}

.table-container{
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  width: 100%;
  height: 100%;
}